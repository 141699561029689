<script setup lang="ts">
import { computed } from 'vue';
import dayjs from 'dayjs';

import { useHelpers } from '@Composables/helpers';
import { useMoney } from '@Composables/money';

import { formatDateWithHour } from '~coreUtils/date';

import Divider from '@Components/Divider.vue';
import TextBody2 from '@Typography/TextBody2.vue';
import TextSubTitle1 from '@Typography/TextSubTitle1.vue';

import { EAuctionStatus } from '@Enums/auctionStatus.model';
import type { IAuctionInfo } from '@/models/auction-info.model';
import type { IItem } from '@/models/filters/item.model';

const {
  advancedDetails,
  card,
  showStartAuctionWithLabel = true,
  isUserItem
} = defineProps<{
  card: IAuctionInfo | IItem;
  advancedDetails?: boolean;
  showStartAuctionWithLabel?: boolean;
  isUserItem?: boolean;
}>();

const { slugify } = useHelpers();
const { format_money } = useMoney();
const { $i18n, $routeNames } = useNuxtApp();
const currentDate: Date = new Date();
const localTime: string = new Date().toISOString();
const actualDate: string = new Date(new Date(localTime).setSeconds(new Date(localTime).getSeconds())).toISOString();

const isWarranty = computed<string | null>(() => {
  return card.margin;
});

const showMarginLabel = computed<string>(() => {
  if (isWarranty.value) {
    return `${$i18n.t('listItem.warranty')}: ${format_money(card.margin)}` as string;
  } else {
    return $i18n.t('listItem.noWarranty') as string;
  }
});

const startAuction = computed(() => {
  const date = (card as IAuctionInfo).startAuctionAt || (card as IItem).startAuctionAt;

  const calculatedDate = calculateDate(date);

  if (calculatedDate !== '') return `${calculatedDate}`;
});

const startAuctionWithLabel = computed(() => {
  const date = (card as IAuctionInfo).startAuctionAt || (card as IItem).startAuctionAt;

  const calculatedDate = calculateDate(date);

  return calculatedDate === ''
    ? $i18n.t('listItem.startAuction')
    : `${$i18n.t('listItem.beginning')}: ${calculatedDate}`;
});

const depositPaidFinished = computed(() => {
  return dayjs(formatDateISO(card.marginDueDate)).diff(formatDateISO(new Date()), 'days') < 0 && isWarranty.value;
});

const itemId = computed(() => card.itemId || card.id);
const itemSlug = computed(() => createSlug(card.notes || card.title || card.itemName));

const redirectObject = computed(() => {
  return isUserItem
    ? {
        name: $routeNames.user.itemDetails(itemId.value),
        params: { itemId: itemId.value }
      }
    : {
        name: $routeNames.item(itemId.value, itemSlug.value),
        params: { id: itemId.value, slug: itemSlug.value }
      };
});

function calculateDate(date: any) {
  const differenceInDays = dayjs(formatDateISO(date)).diff(formatDateISO(new Date(actualDate)), 'day');

  if (differenceInDays >= 1) {
    const prefix = differenceInDays === 1 ? $i18n.t('day') : $i18n.t('days');
    return `${differenceInDays} ${prefix}`;
  } else {
    const diffInSeconds = dayjs(new Date(date)).diff(new Date(), 'second');

    if (diffInSeconds < 0) return '';

    if (diffInSeconds < 0) return '';

    const hours = Math.trunc(diffInSeconds / 3600);

    if (hours < 1) {
      return $i18n.t('listItem.lessThan');
    }
    const hourText = hours > 1 ? $i18n.t('hours') : $i18n.t('hour');

    return `${hours} ${hourText}`;
  }
}

function createSlug(text: string): string {
  return slugify(text);
}

function canShow(data: any) {
  return typeof data !== 'number';
}

function dateIsAfter(date: Date, currentDate: Date): boolean {
  return dayjs(new Date(date)).isAfter(new Date(currentDate));
}

const auctionIsEnded = computed<boolean>(() => {
  const date = (card as IAuctionInfo).endAuctionAt || (card as IItem).endAuctionAt;
  const diffInSeconds = dayjs(new Date()).diff(new Date(date), 'second');

  return diffInSeconds > 0;
});
</script>

<template>
  <NuxtLink
    :to="redirectObject"
    class="text-decoration-none card__link"
    :title="card.notes"
  >
    <div class="card d-flex flex-column justify-space-between mx-xs">
      <div class="card__field">
        <div class="card__top d-flex justify-center align-center">
          <img
            :src="`data:image/png;base64,${card.randomPhoto || card.mainPhoto}`"
            :alt="card.notes"
            class="card__photo"
          />
        </div>
        <TextBody2
          :lowDark="true"
          class="ma-0 first-letter"
          v-if="canShow(card.itemCategory)"
          >{{ card.itemCategory }}</TextBody2
        >
        <Divider />

        <TextSubTitle1
          v-if="card.notes || card.itemName || $slots.title"
          :mediumDark="true"
          class="ma-0 first-letter font--bold ellipsis"
          ><slot name="title">{{ card.notes || card.itemName }}</slot>
        </TextSubTitle1>

        <TextSubTitle1
          v-if="advancedDetails && card.signature"
          :mediumDark="true"
          class="ma-0 first-letter font--bold ellipsis"
          >{{ `${$t('listItem.advanced.signature')}: ${card.signature}` }}</TextSubTitle1
        >

        <TextBody2 :mediumDark="true"> {{ $t('price.base') }}:&nbsp;{{ format_money(card.openingValue) }} </TextBody2>
        <TextBody2 :mediumDark="true"> {{ $t('price.estimate') }}:&nbsp;{{ format_money(card.estimate) }} </TextBody2>

        <TextBody2
          v-if="advancedDetails && card.startAuctionAt"
          :mediumDark="true"
        >
          {{ $t('listItem.advanced.date.start') }}:&nbsp;{{ formatDateWithHour(card.startAuctionAt) }}
        </TextBody2>
        <TextBody2
          v-if="advancedDetails && card.endAuctionAt"
          :mediumDark="true"
        >
          {{ $t('listItem.advanced.date.end') }}:&nbsp;{{ formatDateWithHour(card.endAuctionAt) }}
        </TextBody2>
        <TextBody2 :mediumDark="true">
          {{ showMarginLabel }}
        </TextBody2>
        <TextBody2 :mediumDark="true"> {{ $t('listItem.location') }}:&nbsp;{{ card.city }} </TextBody2>
      </div>
      <div
        v-if="(card.winner || card.notEventuated) && isUserItem"
        :class="{
          'card__footer--won': card.winner && !card.notEventuated,
          'card__footer--not-eventuated': card.notEventuated
        }"
        class="card__footer w-100"
      >
        <TextBody2
          light
          class="w-100 text-center m-3xs"
        >
          {{
            card.winner && !card.notEventuated
              ? card.statusName === EAuctionStatus.IN_PROGRESS
                ? $t('buttons.eAuctionWin')
                : $t('buttons.eAuctionWon')
              : $t('buttons.notEventuated')
          }}
        </TextBody2>
      </div>
      <div class="card__footer w-100">
        <template v-if="auctionIsEnded">
          <TextBody2
            :mediumDark="true"
            class="my-xs d-flex text-lowercase"
          >
            <CdsIcon
              icon="clock"
              class="card__icon mx-xs"
            />
            {{ $t('buttons.eAuctionClosed') }}
          </TextBody2>
        </template>
        <template v-else>
          <TextBody2
            :mediumDark="true"
            class="my-xs d-flex items-center gap-2xs px-sm"
          >
            <CdsIcon
              icon="clock"
              class="card__icon"
            />
            <template v-if="showStartAuctionWithLabel">
              {{ startAuctionWithLabel }}
            </template>
            <template v-else>
              {{ card.status }}<template v-if="startAuction">:</template>
              {{ startAuction }}
            </template>
          </TextBody2>

          <TextBody2
            :mediumDark="true"
            class="my-xs d-flex items-center gap-2xs px-sm"
            v-if="dateIsAfter(card.marginDueDate, currentDate) && card.margin"
          >
            <CdsIcon
              icon="clock"
              class="card__icon"
            />
            {{ `${$t('item.payMarginDate')}: ${calculateDate(card.marginDueDate)} ` }}
          </TextBody2>

          <TextBody2
            :mediumDark="true"
            class="my-xs d-flex items-center gap-2xs px-sm"
            v-else-if="depositPaidFinished"
          >
            <CdsIcon
              icon="clock"
              class="card__icon"
            />
            {{ $t('item.warrantyPaidFinished') }}
          </TextBody2>
        </template>
      </div>
    </div>
  </NuxtLink>
</template>

<style lang="scss" scoped>
@import '@Assets/scss/components/index/card.scss';
</style>
